<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Customer_Right_Customer_CustomerShop_AddEditCustomerShop'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="enabledShop" v-right-code="'Customer_Right_Customer_CustomerShop_StartEndCustomerShop'">启用</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="disableShop" v-right-code="'Customer_Right_Customer_CustomerShop_StartEndCustomerShop'">停用</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="customerShopListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ShopName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadPageList", () => this.reloadPageList());
                this.Event.$on("onAdd", () => this.add());
            });
            this.initialize();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                }
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/customershop/search", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/customershop/getcustomershopbyid", "get", { id: row.ShopID }, (data) => {
                    this.Event.$emit("clearEditForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add() {
                this.$ajax.send("omsapi/customershop/getemptycustomershop", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            enabledShop() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择店铺.`, "error");
                    return false;
                }
                var This = this;
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    if (this.multipleSelection[i].ShopStatus == 100) {
                        This.Utils.messageBox("已启用店铺，不能重复设置", "error");
                        return false;
                    }
                }
                this.Utils.confirm({
                    title: "是否启用店铺?",
                    content: "启用后数据内容会发生改变,是否确认启用?"
                }, () => {

                    this.$ajax.send("omsapi/customershop/setcustomershopstatus", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("店铺启用成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消启用.", "info");
                });
            },
            disableShop() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择店铺.`, "error");
                    return false;
                }
                var This = this;
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    if (this.multipleSelection[i].ShopStatus == 200) {
                        This.Utils.messageBox("已停用店铺，不能重复设置", "error");
                        return false;
                    }
                }
                this.Utils.confirm({
                    title: "是否停用店铺?",
                    content: "停用后数据内容会发生改变,是否确认禁用?"
                }, () => {
                    this.$ajax.send("omsapi/customershop/setcustomershopstatus", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("店铺停用成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消停用.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        }

    }
</script>
<style>
</style>